.btn-activate{
    /* background-color: #3c8f72; */
    border: 1px solid #F8E16C;
    color: white;
    padding: 0.4rem;
    transition: all .3s ease-in;
}

.btn-normal{
    /* background-color: #9ad8c3; */
    border: 1px solid #9ad8c3;
    color: #000; 
    padding: 0.4rem;
    transition: all .3s ease-in;
}