.demo-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.563) 0px 5px 15px;;
    /* background: linear-gradient(120deg,#007C64,#55b49b); */
    background: linear-gradient(120deg,#156064,#00C49A);
}

.btn-lg {
    padding: 12px 26px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
}

::placeholder {
    font-size: 14px;
    letter-spacing: 0.5px;
}

.form-control-lg {
    font-size: 16px;
    padding: 25px 20px;
}

.login-label {
    font-size: 1rem;
    font-weight: 500;
}

.login-title {
    font-size: 1.5rem;
    color: #000;
    font-weight: 500;
    display: flex;
    padding-top: 3rem;
    justify-content: center;
}

.login-sub-title {
    font-size: 1.3rem;
}

.image-size-small {
    width: 140px;
    margin: 0 auto;
}

.image-size-small img {
    width: 140px;
    margin-bottom: -70px;
}

.login-panel {
    padding: 3rem;
}

.Icon-outside {
    display: flex;
   }
   .Icon-outside input:focus{
     box-shadow: #156064 !important;
   }
   
   .Icon-outside i{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2.4rem;
    margin-right: -3px;
    /* border: 1px solid #000; */
    /* border-radius: 50%; */
    padding: 6px 15px;
    margin-bottom: 1rem;
    background-color: #156064;
    color: #fff;
    border-top-left-radius: 4px; 
    border-bottom-left-radius: 4px; 
   }

   .Icon-outside1 {
    display: flex;
   }
   
   .Icon-outside1 i{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2.4rem;
    margin-right: -3px;
    /* border: 1px solid #000; */
    /* border-radius: 50%; */
    padding: 6px 15px;
    /* margin-bottom: 1rem; */
    background-color: #156064;
    color: #fff;
    border-top-left-radius: 4px; 
    border-bottom-left-radius: 4px; 
   }

   /* Pure CSS for modern input styling */
.custom-input {
    width: 100%; 
    max-width: 500px; 
    padding: 12px; 
    border: 1px solid #ccc; 
    /* border-top-right-radius: 4px; 
    border-bottom-right-radius: 4px;  */
    font-size: 16px; 
    transition: border-color 0.3s, box-shadow 0.3s;
}

.custom-input:focus {
    border-color: #156064; 
    box-shadow: 0 0 5px rgba(11, 129, 58, 0.5); 
    outline: none; 
}

.custom-input:hover {
    border-color: #156064; 
}









   @media  screen and (max-width: 360px) {
    .image-size-small img {
        width: 100px;
    }
    .Icon-outside i,.Icon-outside1 i{
        font-size: 1.2rem;
    }
   .custom-input{
        font-size: 0.9rem;
    }
    .login-sub-title {
        font-size: 1rem;
    }
    .login-title {
        padding-top: 1rem;
    }
    .login-panel {
        padding: 1rem;
    }
    .login-label {
        font-size: 0.875rem;
    }
   }

   @media  screen and (max-width: 576px) {
    .image-size-small img {
        width: 120px;
    }
    .sub-title {
        font-size: 1rem;
    }
    .login-panel {
        padding: 1rem;
    }
    .login-sub-title {
        font-size: 1.2rem;
    }
   }

   @media  screen and (max-width: 768px) {
    .Icon-outside i,.Icon-outside1 i{
        font-size: 1.4rem;
    }
    .Icon-outside input,.Icon-outside1 input{
        font-size: 1rem;
    }
   }

   @media  screen and (max-width: 992px) {
   
   }

   @media  screen and (max-width: 1200px) {
   
   }