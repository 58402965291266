@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&family=Poppins:wght@400;500;600&display=swap');

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.App {
    overflow-x: hidden;
    /* background: linear-gradient(120deg,#3b7197,#00B3B3); #4C7766 #EBE6E0*/
    /* background-color: rgba(245, 245, 245, 0.816); */
    /* background-color: whitesmoke;  */
    height: 100vh;
    margin-left: 3px;
    margin-right: 3px;
}

html,
body {
    height: 100%;
}

hr {
    margin: 0.5rem 0;
}

.modal-content {
    border-left: 5px solid #156064;
}

#deletealertModal .modal-content {
    border-left: 5px solid red;
}
.form-check-input[type=checkbox]{
  cursor:  pointer !important;
}
.form-check-input[type=radio]{
    cursor:  pointer !important;
  }
/*----------- Start Nav Bar Section --------*/
.odds-nav {
    /* background-color: #00636E; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 4rem;
    margin-bottom: 0.5rem;
}

.profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile .info {
    color: white;
    margin-left: 1rem;
    font-weight: 400;
    font-size: larger;
}

.title {
    color: white;
    display: flex;
    align-items: center;
}

.title span {
    font-size: 1.5rem;
}

.logLink {
    display: flex;
    align-items: center;
}

.group-list .btn-label{
    font-size: 0.875rem;
}

.lang-ico{
    font-size: 1rem;
}
.lang-title{
    font-size: 0.45rem;
}
.lang-value{
    font-size: 0.4rem;
    font-weight: bold;
}

/*----------- End Nav Bar Section --------*/

/*----------- Start Link Button Group Section --------*/
.group-list {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-wrap: wrap;
    /* margin-bottom: 1rem; */
}

.group-list .link-btn {
    margin-left: .5rem;
    /* background-color: #00636E; */
    background-color: #156064;
    color: white;
    margin-bottom: 5px;
}

.group-list .link-btn:hover {
    background-color: #237e88;
    color: whitesmoke;
}

.group-list .link-btn-active {
    margin-left: .5rem;
    /* background-color: #ffc107; */
    background-color: #F8E16C;
    color: black;
    margin-bottom: 5px;
}

.group-list .link-btn-active:hover {
    background-color: hsl(45, 77%, 64%);
    color: black;
}

/*----------- End Link Button Group Section --------*/

/*----------- Start Events Section --------*/
.main {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
}

.right {
    width: 300px;
}

.middle {
    width: 500px;
}

.left {
    width: 500px;
}

.table-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.875rem;
}

.input-gp {
    display: flex;
}

.site-header {
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    font-weight: bold;
}

.bet-container .event {
    margin: 0 .3rem;
    height: 65vh;
    overflow-y: scroll;
    overflow-x: none;
    scroll-behavior: smooth;
}

.bet-container .event table,
.bet-container .panel .panel-details table {
    font-size: 0.875rem;
}

.bet-container .event table td,
.bet-container .event table th {
    text-align: left;
    padding: 0.5rem 0.2rem;
}

.bet-container .event table .team {
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.bet-container .event table .team:hover {
    color: rgb(5, 181, 76);
}

.bet-container .panel {
    margin: .3rem .3rem;
}

.bet-container .panel table {
    font-size: large;
}

.bet-container .panel .panel-details {
    border: 2px solid #ddd;
    border-bottom: none;
    overflow-x: scroll;
}

.bet-container .panel select {
    width: 50%;
}

.bet-container .panel button span {
    font-size: 0.875rem;
}

.bet-container .event::-webkit-scrollbar-track {
    border: 1px solid #00636E;
    background-color: #F5F5F5;
    border-radius: 8px;
}

.bet-container .event::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #F5F5F5;
}

.bet-container .event::-webkit-scrollbar-thumb {
    background-color: #00636E;
    border-radius: 8px;
}

.page-link {
    font-size: 0.8rem;
}

/*--------Start Report Site-------------------*/
.search-btn,.search-btn2 {
    margin-top: 1.65rem;
}

.search-input-panel{
    display: flex;
    justify-content: end;
    align-items: center;
}

.search-txt {
    width: 200px;
    margin-top: 5px;
}

.report-table {
    /* font-size: 1.3rem; */
    overflow-x: scroll;
}

/*--------end report -------------------*/

/*--------start history section -------------------*/
.history-container::-webkit-scrollbar-track {
    border: 1px solid #00636E;
    background-color: #F5F5F5;
}

.history-container::-webkit-scrollbar {
    width: 7px;
    height: 5px;
    background-color: #F5F5F5;
}

.history-container::-webkit-scrollbar-thumb {
    background-color: #00636E;
    border-radius: 8px;
}
/*--------end history section -------------------*/

/*--------start history section -------------------*/
.customer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.customer-header .customer-txt {
    width: 200px;
}

/*--------end history section -------------------*/

/*--------start modal section -------------------*/
.event-details {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
}

.event-details .league {
    font-weight: bolder;
    font-size: 1.2rem;
}

.amt-edit {
    display: flex;
    align-items: center;
}

.amt-edit input {
    width: 100px;
    margin-left: 5px;
}

.delete-body {
    display: flex;
    flex-direction: column;
}

.delete-body span {
    font-weight: bold;
    font-size: 1rem;
}

.delete-body span i {
    font-weight: bold;
    font-size: 1.5rem;
    color: red;
    margin-right: 5px;
}

.delete-body .delete-btn-gp {
    display: flex;
    justify-content: flex-end;
}

/*--------end modal section -------------------*/

.accordion-button:focus {
    z-index: 0;
    border-color: none;
    outline: 0;
    box-shadow: none;
}

/* body {
    font-family: 'Roboto', sans-serif;
background-image: linear-gradient(to top, #4a8db7 0%, #74bde0 100%);
} */

/* Custom CSS for iPhone-style modal */
.iphone-modal {
    border-radius: 16px; /* Rounded corners for iPhone-like look */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    border: none; /* Remove border */
    background-color: rgba(255, 255, 255, 0.95); /* Slightly translucent background */
    overflow: hidden; /* Clip inner elements */
}

/* Header styling similar to iOS */
.iphone-modal .modal-header {
    border-bottom: 1px solid #e0e0e0; /* Light gray bottom border */
    padding: 12px 16px; /* Padding for spacing */
}

.iphone-modal .modal-title {
    font-weight: 500; /* Moderate font weight */
    font-size: 18px; /* Slightly larger font size */
    margin: 0; /* Remove default margin */
}

.iphone-modal .btn-close {
    background: none; /* Remove default background */
    border: none; /* Remove border */
    font-size: 1.5rem; /* Adjust icon size */
    opacity: 0.5; /* Subtle visibility */
    transition: opacity 0.2s; /* Smooth transition for hover effect */
}

.iphone-modal .btn-close:hover {
    opacity: 1; /* Full visibility on hover */
}

/* Body styling similar to iOS */
.iphone-modal .modal-body {
    padding: 16px; /* Padding for spacing */
    font-size: 16px; /* Font size for readability */
    color: #333; /* Darker text color */
}

/* Footer styling similar to iOS */
.iphone-modal .modal-footer {
    border-top: 1px solid #e0e0e0; /* Light gray top border */
    padding: 12px 16px; /* Padding for spacing */
    display: flex;
    justify-content: flex-end; /* Right-aligned buttons */
    gap: 10px; /* Spacing between buttons */
}

.iphone-modal .btn {
    border-radius: 8px; /* Rounded buttons for a more iOS-like look */
    padding: 8px 16px; /* Padding for a comfortable button size */
    transition: background-color 0.2s; /* Smooth background transition */
}

.iphone-modal .btn-secondary {
    background-color: #f0f0f0; /* Light gray for secondary button */
    color: #000; /* Black text */
}

.iphone-modal .btn-primary {
    background-color: #007aff; /* iOS-style blue for primary button */
    color: #fff; /* White text */
}

.iphone-modal .btn-primary:hover {
    background-color: #005bb5; /* Darker blue on hover */
}

.red-text {
    color: red !important;
}

.form-check-input:checked {
    background-color: #156064;
    border-color: #156064;
}

input[type="checkbox"] {
    accent-color: #156064;
}

input[type="checkbox"]:hover {
    color: #156064;
}
  









/*------ media query ---------*/

@media screen and (max-width: 600px) {
    .profile .info {
        font-weight: 400;
        font-size: medium;
    }

    .profile img {
        width: 20px;
        height: 20px;
    }

    .logout .logLink .loggingOut {
        display: none;
    }

    .odds-nav {
        padding: 0.75rem 2rem;
    }

    .bet-container .event table {
        font-size: 0.8rem;
    }

    .bet-container .panel table {
        font-size: 0.8rem;
    }
}

@media screen and (min-width: 600px) {
    .logout .logLink .loggingOut {
        display: block;
    }
}

@media screen and (max-width:360px) {
    .group-list .btn-label {
        font-size: 0.75rem;
    }

    .input-gp {
        width: 50%;
    }
}

@media screen and (max-width:387px){
    .search-btn2 {
        margin-top: 0;
    }
}

@media screen and (min-width:770px) {
    .group-list {
        justify-content: center;
    }
}

@media screen and (max-width:400px) {
    .lang-ico{
        font-size: 0.8rem;
    }
    .lang-title{
        font-size: 0.4rem;
    }
    .lang-value{
        font-size: 0.3rem;
    }
}

@media screen and (max-width : 700px) {
    .site-header {
        font-size: 1rem;
        margin-bottom: 3px;
    }
}

@media screen and (max-width :1000px) {
    .bet-container .panel select {
        width: 100%;
    }
}

@media screen and (max-width : 430px) {
    .diamond {
        display: none;
    }
}

@media  screen and (max-width: 576px) {
    .group-list .btn-label {
        font-size: 0.8rem;
    }
    .site-header {
        font-size: 0.9rem;
        margin-bottom: 3px;
    }
}

@media  screen and (max-width: 768px) {
    .title {
        display: none;
    }
    .group-list .btn-label {
        font-size: 0.87rem;
    }
    .search-input-panel{
        justify-content: start;
    }
    .search-txt {
        width: 100%;
        margin-top: 0;
    }
    .my-icon{
        display: none;
    }
} 